/*
 *  Resumable file upload
 */

function resumableInit(elem) {
    var $elem = $(elem);
    var accept = $elem.prop('accept');

    $('<input type="text" name="' + $elem.prop('name') + '" style="display: none;"/>').insertAfter($elem);
    $(elem).next().prop('required', $elem.prop('required'));
    $elem.prop('required', false);
    $elem.hide();
    var resumable = new Resumable({
        target: $elem.data('url'),
        chunkSize: 1 * 1024 * 1024, // 1MB
        simultaneousUploads: 1,

        testChunks: false,
        throttleProgressCallbacks: 1,
        // Append token to the request - required for web routes
        query:{_token : $('input[name=_token]').val()}
    });

    resumable.assignBrowse($elem);
    resumable.input = elem;

    $elem.prop('accept', accept);
    $elem.show();
    resumable.on('fileAdded', function (file) {
        this.input.setCustomValidity('El archivo no ha terminado de cargar');
        this.upload();
        $(this.input).data('filename', file.uniqueIdentifier);
        $(this.input).trigger('file-added');
    });

    resumable.on('fileProgress', function (file) {
        $(this.input).data('progress', file.progress());
        $(this.input).data('filename', file.uniqueIdentifier);
        $(this.input).trigger('file-progress');
    });

    resumable.on('fileSuccess', function (file, message) {

        $(this.input).next().val(JSON.parse(message).filepath);
        $(this.input).data('filepath', JSON.parse(message).filepath.split('\\').join('/'));
        $(this.input).trigger('file-success');
        this.input.setCustomValidity('');
    });

    resumable.on('fileError', function (file, message) {
        $(this.input).trigger('file-error');
        this.input.setCustomValidity('');
    });
}

$(document).on('load-resumable', '.resumable-input', function() {
    resumableInit(this);
});

$(document).ready(function() {
    $('.resumable-input').trigger('load-resumable');
});

/*
 *  Resumable + Bootstrap 4 Custom File Input
 */
$(document).on('file-added', '.custom-file-input.resumable-input', function() {
    $label = $(this).closest('.custom-file').find('.custom-file-label');
    if ($label.length === 0 && $(this).prop('id')) {
        $label = $('.custom-file-label[for="' + $(this).prop('id') + '"]');
    }
    $label.html('<span class="custom-file-progress"></span> - <span class="custom-file-filename"></span>');
    $label.find('.custom-file-filename').html($(this).data('filename'));
    $label.find('.custom-file-progress').html('0%');
});

$(document).on('file-progress', '.custom-file-input.resumable-input', function() {
    $label = $(this).closest('.custom-file').find('.custom-file-label');
    if ($label.length === 0 && $(this).prop('id')) {
        $label = $('.custom-file-label[for="' + $(this).prop('id') + '"]');
    }
    $label.find('.custom-file-progress').html(Math.floor($(this).data('progress') * 100) + '%');
});

$(document).on('file-success', '.custom-file-input.resumable-input', function() {
    $label = $(this).closest('.custom-file').find('.custom-file-label');
    if ($label.length === 0 && $(this).prop('id')) {
        $label = $('.custom-file-label[for="' + $(this).prop('id') + '"]');
    }
    $label.find('.custom-file-progress').html('OK');
});

$(document).on('file-error', '.custom-file-input.resumable-input', function() {
    $label = $(this).closest('.custom-file').find('.custom-file-label');
    if ($label.length === 0 && $(this).prop('id')) {
        $label = $('.custom-file-label[for="' + $(this).prop('id') + '"]');
    }
    $label.find('.custom-file-progress').html('ERROR');
});
